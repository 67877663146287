<!--
 * @Author: 张博洋
 * @Date: 2021-09-05 17:26:58
 * @LastEditTime: 2022-01-17 11:23:16
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/pdf/pdf.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div style="overflow-y:auto">
    <pdf v-for="i in numPages"
         :key="i"
         :src="url"
         :page="i"></pdf>
         <!-- <iframe src="url" frameborder="0"></iframe> -->
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import CMapReaderFactory from 'vue-pdf/src/CMapReaderFactory.js'
    // 有时PDF文件地址会出现跨域的情况,这里最好处理一下
    
export default {
  components: {
    pdf,
  },
  data() {
    return {
      url: '',
      numPages: 1,
    }
  },
  created() {
    console.log(pdf)
  },
  mounted() {
    this.url = pdf.createLoadingTask({
      url:this.$route.query.src,
      CMapReaderFactory
	})
    // this.getNumPages()
  },
  methods: {
    // getNumPages() {
    //   this.url = pdf.createLoadingTask({
    //     url: 'https://hospital-1252497236.cos.ap-beijing.myqcloud.com/yfz_dev/prescription/20220222/264.pdf',
    //     // cMapUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.6.347/cmaps/",
    //     cMapUrl: "https://saas-front-static-resources-test.oss-cn-beijing.aliyuncs.com/static-page/pdfjs-dist/cmaps/",
    //     cMapPacked: true
    //   })
     
    // },
  },
}
</script>